<template>
  <div id="table-content">
    <el-table
      :data="data"
      border
      :max-height="height"
      :row-style="{ height: '50px', 'font-size': '16px' }"
      style="width: 100%"
      :header-cell-style="{
        'font-size': '18px',
        'font-weight': '700',
        color: 'rgba(0, 0, 0,0.85)',
      }"
    >
      <el-table-column
        type="index"
        width="80"
        align="center"
        label="编号"
      ></el-table-column>
      <el-table-column
        prop="studentName"
        label="姓名"
        max-width="128"
        align="center"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sno"
        label="学号"
        max-width="146"
      ></el-table-column>
      <el-table-column
        align="center"
        v-if="type"
        prop="score"
        label="成绩"
        max-width="173px"
      ></el-table-column>
      <el-table-column align="center" v-else prop="score" label="成绩" max-width="173px">
        <template slot-scope="scope">
          <el-input
            size="mini"
            type="number"
            v-model="scope.row.score"
            oninput="if(isNaN(value)) { value = parseFloat(value) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}"
            @blur="blur"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="parent1"
        label="家长姓名"
        max-width="186px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="parent1Mobile"
        label="手机号码"
        max-width="200px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="parent2"
        label="家长姓名"
        max-width="186px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="parent2Mobile"
        label="手机号码"
        max-width="200px"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "resultsTable",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
    type: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    height: {
      type: String,
      default: () => {
        return "306";
      },
    },
  },
  components: {},
  data() {
    return {
      results: [],
    };
  },
  created() {
    // console.log(this.todos, this.$store.state.todos);
  },
  updated() {
    // console.log("updated", this.type);
  },
  mounted() {
    // console.log("created", this.type);
  },
  methods: {
    changeScore(val, row) {
      // console.log(val, row);
    },
    blur() {
      this.$emit("upEntryForm", this.data);
    },
  },
  computed: {
    ...mapState(["todos"]),
  },
};
</script>
<style lang="scss" scoped>
#table-content {
  width: 1000px !important;

  margin: 0 auto;
  .el-table {
  }
}
</style>
