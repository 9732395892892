<template>
  <el-container>
    <el-aside width="370px">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        v-if="submenuList.length"
      >
        <el-submenu v-for="(item, index) in submenuList" :index="index + ''" :key="index">
          <span slot="title">{{ submenuLsit(item) }}</span>
          <el-menu-item
            v-for="(val, i) in itemList[index]"
            :key="i"
            :index="index + '-' + i"
            @click="getReportByID(val.id)"
          >
            {{ val.examType }}{{ val.title }} &nbsp; &nbsp; ({{
              val.examDate.slice(0, 4) +
              val.examDate.slice(5, 7) +
              val.examDate.slice(8, 10)
            }})
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-main>
      <p class="tableName">
        <i class="el-icon-caret-right"></i>
        <span v-if="dataAbout">&nbsp;{{ addReportTitle() }}</span>
        <span v-else>请选择考试信息</span>
      </p>
      <el-tabs v-model="activeName" @tab-click="tabChange">
        <el-tab-pane name="1">
          <div slot="label">
            <el-radio v-model="activeName" label="1">按姓名查看</el-radio>
          </div>
          <results-table :height="'410'" :data="dataList"></results-table>
        </el-tab-pane>
        <el-tab-pane name="2">
          <div slot="label">
            <el-radio v-model="activeName" label="2">按名次查看</el-radio>
          </div>
          <results-table :height="'410'" :data="dataList"></results-table>
        </el-tab-pane>
      </el-tabs>
      <el-button class="postAG" @click="postAG">发送</el-button>
    </el-main>
  </el-container>
</template>
<script>
import resultsTable from "@/components/resultsTable";
import http from "@/http/api";
export default {
  name: "",
  props: {},
  components: { resultsTable },
  data() {
    return {
      activeName: "2",
      submenuList: [],
      itemList: [],
      dataList: [],
      dataAbout: null,
      userInfo: {
        id: "",
        mobile: "",
        orgId: "",
        orgName: "",
        orgType: "",
        realName: "",
        username: "",
      },
      sort: "",
      sortid: window.localStorage.getItem("sendReportID") || "",
    };
  },
  created() {
    if (this.$route.params.id) {
      window.localStorage.setItem("sendReportID", this.$route.params.id);
      // 获取成绩列表
      this.getReportByID(this.$route.params.id);
    } else {
      this.getReportByID();
    }

    // 获取班级列表
    this.getClassList();
    // 获取用户信息
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    // 获取用户信息
    async getUserInfo() {
      const data = await http.getUserInfo();
      if (data.status == 200) {
        this.userInfo = data.data;
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取成绩单列表
    async getReportedList(val) {
      const data = await http.getReportedList({
        pageNumber: 0,
        size: 100,
        ...val,
      });
      if (data.status == 200) {
        if (data.data) {
          this.itemList.push(data.data);
        }
      } else {
        this.$message.error({
          message: data.msg || "获取成绩单列表失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取班级列表
    async getClassList() {
      const data = await http.getClassList();

      if (data.status == 200) {
        this.submenuList = data.data;
        this.submenuList.forEach((e) => {
          this.getReportedList(e);
        });
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取成绩单
    async getReportByID(id) {
      if (id) {
        window.localStorage.setItem("sendReportID", id);
        this.sortid = id;
      }
      if (!this.sortid) return null;
      const { data: data } = await http.getReportByID(this.sortid, {
        sort: this.sort,
      });

      if (data.id) {
        this.dataAbout = data;
        this.dataList = data.details;
      } else if (data.length) {
        this.dataAbout = data;
        this.dataList = data.details;
      } else {
        this.$message.error({
          message: "获取成绩单失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 排序切换
    tabChange() {
      if (this.activeName == 1) {
        this.sort = "name";
      } else {
        this.sort = "";
      }
      this.getReportByID();
    },
    // 再次发送
    async postAG() {
      if (this.sortid) {
        const { data: data } = await http.sengReportByID({
          reportId: this.sortid,
        });
        if (data.code == 200) {
          this.$message.error({
            message: "发送成功",
            type: "error",
            duration: 3000,
          });
        }
      } else {
        this.$message.error({
          message: "无数据发送",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 录入成绩单标题
    addReportTitle() {
      let school = this.userInfo.orgName;
      let grade = this.dataAbout.grade.slice(0, 1);
      // if (this.dataAbout.grade == "一年级") {
      //   grade = "1";
      // } else if (this.dataAbout.grade == "二年级") {
      //   grade = "2";
      // } else if (this.dataAbout.grade == "三年级") {
      //   grade = "3";
      // } else if (this.dataAbout.grade == "四年级") {
      //   grade = "4";
      // } else if (this.dataAbout.grade == "五年级") {
      //   grade = "5";
      // } else if (this.dataAbout.grade == "六年级") {
      //   grade = "6";
      // } else if (this.dataAbout.grade == "初一年级") {
      //   grade = "7";
      // } else if (this.dataAbout.grade == "初二年级") {
      //   grade = "8";
      // } else if (this.dataAbout.grade == "初三年级") {
      //   grade = "9";
      // }
      let classes = "";
      if (this.dataAbout.classes.length == 3) {
        classes = ` (${this.dataAbout.classes.slice(0, 2)}) 班`;
      } else if (this.dataAbout.classes.length == 2) {
        classes = ` (${this.dataAbout.classes.slice(0, 1)}) 班`;
      }
      let course = this.dataAbout.course;
      let userTitle = this.dataAbout.title;
      let examDate =
        this.dataAbout.examDate.slice(0, 4) +
        this.dataAbout.examDate.slice(5, 7) +
        this.dataAbout.examDate.slice(8, 10);
      let examType = this.dataAbout.examType;
      return `${school}${grade}${classes}-${course}${examType}${userTitle} ( ${examDate} ) 成绩单`;
    },
  },
  computed: {
    submenuLsit() {
      return (item) => {
        let grade = item.grade.slice(0, 1);
        // if (item.grade == "一年级") {
        //   grade = "1";
        // } else if (item.grade == "二年级") {
        //   grade = "2";
        // } else if (item.grade == "三年级") {
        //   grade = "3";
        // } else if (item.grade == "四年级") {
        //   grade = "4";
        // } else if (item.grade == "五年级") {
        //   grade = "5";
        // } else if (item.grade == "六年级") {
        //   grade = "6";
        // } else if (item.grade == "初一年级") {
        //   grade = "7";
        // } else if (item.grade == "初二年级") {
        //   grade = "8";
        // } else if (item.grade == "初三年级") {
        //   grade = "9";
        // }
        let classes = "";

        if (item.classes.length == 3) {
          classes = ` (${item.classes.slice(0, 2)}) 班`;
        } else if (item.classes.length == 2) {
          classes = ` (${item.classes.slice(0, 1)}) 班`;
        }
        let course = item.course;
        return `${grade}  ${classes}${course}`;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-container {
  padding: 50px 38px;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  .el-aside {
    margin-right: 22px;
    height: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 33px 0;
    box-sizing: border-box;
    text-align: left;
    .el-menu {
      border-right: none;
      .el-submenu {
        .el-submenu__title {
          height: 50px;
          line-height: 50px;
          padding: 0 27px !important;
          &:hover {
            background-color: #fff;
          }
          padding: 0;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #eb6123;
          i {
            color: #eb6123;
            font-size: 20px;
            right: 35px;
            font-weight: 700;
          }
        }
        .el-menu-item {
          padding: 0 27px !important;
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 2px;
          border-right: 3px solid transparent;
        }
        .el-menu-item:hover {
          background-color: rgba(206, 63, 63, 0.1);
        }
        .el-menu-item.is-active {
          background: rgba(209, 62, 62, 0.1);
          border-right: 3px solid #eb6123;
        }
      }
    }
  }
  .el-main {
    width: 1084px;
    border-radius: 16px;
    height: 100%;
    background-color: #fff;
    text-align: left;
    .tableName {
      line-height: 120px;
      padding-left: 50px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #eb6123;
      i {
        width: 20px;
        height: 20px;
        transform: scale3d(1.8, 1.2, 1);
        margin: 0 10px;
        text-shadow: 2px 0 3px #eb6123;
      }
    }
    .el-tabs {
      margin-top: 24px;
      height: 480px;
      .el-tabs__header {
        margin-bottom: 25pxpx;
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            .el-tabs__nav {
              left: 50%;
              margin-left: -244px;
              .el-tabs__active-bar {
                display: none;
              }
              .el-tabs__item {
                margin: 0 40px;
                .el-radio__label {
                  font-size: 22px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(0, 0, 0, 0.85);
                }
                .el-radio__inner {
                  width: 24px;
                  height: 24px;
                  font-size: 22px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(0, 0, 0, 0.85);
                  background: #fff;
                  border-color: #eb6123;
                  vertical-align: middle;
                  transform: translateY(-3px);
                }
                .el-radio__input.is-checked .el-radio__inner {
                  border-color: #eb6123;
                  background: #fff;
                }
                .el-radio__inner::after {
                  width: 12px;
                  height: 12px;
                  background: #eb6123;
                }
              }
            }
          }
          &::after {
            display: none;
          }
        }
      }
    }
    .postAG {
      /*  margin: 0 auto;
      display: block;*/
      display: none;
      width: 352px;
      height: 56px;
      border-radius: 28px;
      border: 1px solid #eb6123;
      margin: 10px auto;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #eb6123;
      &:focus {
        background: unset;
      }
      &:hover {
        background: unset;
        color: #eb6123;
        box-shadow: 0px 0px 5px 3px rgba($color: #eb6123, $alpha: 0.1);
      }
    }
  }
}
</style>
